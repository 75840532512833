@import 'react-toastify/dist/ReactToastify.css';
/* @import "./assets/css/react-datepicker.css"; */
@import 'react-datepicker/dist/react-datepicker.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .theme-rounded {
    @apply rounded-md;
  }
  body {
    @apply bg-gray-100;
  }
  ::selection {
    @apply text-white bg-primary-500;
  }
  .listbox {
    @apply w-full text-white bg-input p-2 rounded-md text-left;
  }
  .drop-zone {
    @apply bg-input text-center overflow-hidden rounded-md border-4 border-dashed border-primary-500 cursor-pointer hover:border-primary-400;
  }
  /* select:required:invalid {
    color: gray;
  }
  option[value=""][disabled] {
    display: none;
    @apply text-muted
  }
  option {
    color: black;
  } */
  a {
    @apply text-primary-700 hover:text-primary-500 duration-500 focus:outline-primary-500 focus:text-primary-500;
  }
  .navigation a {
    @apply text-black hover:text-primary-500 duration-500 focus:text-primary-500;;
  }
  .text-primary {
    @apply text-primary-500;
  }
  .text-secondary {
    @apply text-secondary-500;
  }
  .company-nav a {
    @apply hover:text-muted;
  }
  .company-nav a.active {
    @apply text-white;
  }
  .btn {
    @apply py-2 px-6 rounded-md duration-300 outline-0 border-2 border-transparent text-inherit;
  }

  button.primary,
  .btn-primary {
    @apply bg-primary-500 disabled:bg-input disabled:cursor-not-allowed text-white hover:text-white hover:bg-primary-600  outline-1 outline-white focus:ring-2 focus:ring-primary-300;
  }
  button.back,
  .btn-back {
    @apply text-muted hover:text-white px-0;
  }
  button.secondary,
  .btn-secondary {
    @apply text-muted hover:text-white hover:bg-input focus:border-muted;
  }
  .bg-primary {
    @apply bg-primary-500;
  }

  .react-datepicker {
    @apply border-primary-500;
  }
  .react-datepicker select {
    @apply text-black border-primary-500 my-2;
  }
  .bg-success {
    @apply bg-green-700;
  }
  .bg-info {
    @apply bg-indigo-600;
  }
  .bg-warning {
    @apply bg-yellow-600;
  }
  .bg-danger {
    @apply bg-red-400;
  }
  .text-success {
    @apply text-green-500;
  }
  .text-danger {
    @apply text-red-300;
  }
  .bg-auth {
    @apply bg-white;
  }
  .dropdown {
    @apply relative;
  }
  .dropdown-menu {
    @apply absolute bg-gray-50 outline-2 p-1 shadow-md mt-2 theme-rounded w-44 text-left -left-3;
  }
  /* .dropdown-menu li {
    @apply block w-full
  } */
  .dropdown-menu li a {
    @apply p-2 hover:bg-primary-500 hover:text-white block;
  }
  svg {
    @apply inline-block;
  }
  ol li {
    @apply mb-1;
  }
}

@layer components {
  .form-item {
    @apply mb-4;
  }

  .form-item-label {
    @apply block text-sm mb-1;
  }
  .form-item-label.required::after {
    content: '*';
    @apply text-danger pl-1;
  }
  input,
  textarea,
  select {
    @apply w-full bg-gray-100 p-2 rounded-md outline-0 border-2 border-transparent focus:ring-2 ring-primary-500 placeholder:text-muted placeholder:text-sm;
  }
  .input-check-item {
    @apply flex space-x-3 items-end
  }
  [type="checkbox"] {
    @apply w-5 h-5 focus:outline-2 focus:outline-primary-500;
  }

  input.invalid,
  textarea.invaid,
  select.invalid {
    @apply border-red-300;
  }
  [type="checkbox"].invalid {
    @apply ring-1 ring-offset-1 outline-offset-2 ring-red-300 rounded-md
  }
  .invalid-msg {
    @apply text-red-300 text-xs mt-1;
  }

  /* select option {
    @apply text-white;
  } */
  .placeholder-text {
    @apply text-muted;
  }

  [type='checkbox']:checked {
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    @apply bg-primary-500;
  }

  /*
  [type="radio"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23262626' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  }

  [type="checkbox"],
  [type="radio"] {
    @apply focus:ring-0 focus:ring-offset-0;
  }

  [type="checkbox"],
  [type="checkbox"]:checked,
  [type="checkbox"]:checked:hover,
  [type="checkbox"]:checked:focus,
  [type="checkbox"]:indeterminate:hover,
  [type="radio"],
  [type="radio"]:checked,
  [type="radio"]:checked:hover,
  [type="radio"]:checked:focus {
    @apply border-gray-300;
  }
  */
}
